body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

#root {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 10px;
  margin: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header{
  display: grid;
  grid-template-columns: 1fr;
}

.header__btns {
  display: grid;
  justify-content: center;
  gap: 6px;
}

button{
  line-height: 28px;
  font-weight: 600;
  border-radius: 4px;
  font-size: 12px;
  align-content: center;
  width: 80vw;
  padding: 0 20px;
  background-color: white;
  color: black;
  border: 2px solid #555555;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.button:hover {
  background-color: #555555; /* Green */
  color: white;
}

img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.images_section {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  gap: 12px;
}

.img__item {
  display: grid;
  position: relative;
  padding: 0 0 85% 0;
}

@media (min-width: 565px) {
  .header{
    grid-template-columns: 1fr auto 1fr;
    align-content: center;
    row-gap: 12px;
  }

  .header__btns {
    grid-column: 2/3;
    grid-template-columns: repeat(3, 1fr);

  }

  .images_section {
    grid-template-columns: repeat(2, 1fr);
  }
  button{
    justify-self: center;
    width: max-content;
  }

}

@media (min-width: 900px) {

  .images_section {
    grid-template-columns: repeat(4, 1fr);
  }
}